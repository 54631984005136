<template>
  <div>
    <v-container>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ประเภทวิชา/หมวดวิชา/สาขาวิชา : จากระบบ ศธ.02 "
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="8" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
              <v-form ref="updaterate_work_course_stdform" lazy-validation>
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="type_courseSelect"
                      outlined
                      label="ประเภทวิชา"
                      :items="subject_types"
                      item-text="subject_typeName"
                      item-value="subject_typeName"
                      @change="rate_work_course_stdSearch()"
                      require
                      :rules="[v => !!v || '']"
                    >
                  <template v-slot:item="{item}">
                    <div>
                      <div>{{ item.subject_typeID }} : {{ item.subject_typeName }} </div>
                      <div></div>
                </div>
                  </template>
                  </v-autocomplete>
                  </v-col>
                  <v-col cols="4"
                    ><v-text-field
                      outlined
                      label="กำหนดรหัสประเภทวิชา"
                      v-model="editrate_work_course_std.id_type_course"
                      require
                      :rules="[v => !!v || '']"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="2"
                    ><v-btn
                      color="primary"
                      @click="rate_work_course_stdUpdate()"
                      >บันทึก</v-btn
                    ></v-col
                  >
                </v-row>
              </v-form>
            </v-col>

            <v-col cols="12" lg="4" class="text-right">
              <v-btn
                block
                large
                right
                depressed
                color="primary"
                @click.native="rate_work_course_stdAdd()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>

              <v-btn
                block
                large
                right
                depressed
                color="info"
                @click.native="rate_work_course_stdQueryAll()"
              >
                <v-icon>mdi-content-paste</v-icon>แสดงรายการ
              </v-btn>

              <v-btn
                block
                large
                right
                depressed
                color="warning"
                @click.native="man_powerCSV()"
              >
                <v-icon>mdi-file-plus</v-icon>นำเข้าข้อมูลไฟล์
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="rate_work_course_stds"
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"
              @click.stop="rate_work_course_stdEdit(item.rate_id_course)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon
              color="red"
              @click.stop="rate_work_course_stdDelete(item.rate_id_course)"
            >
              mdi-delete
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addrate_work_course_stddialog  -->
      <v-layout row justify-center>
        <v-dialog
          v-model="addrate_work_course_stddialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มข้อมูลสาขาวิชาเอก"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addrate_work_course_stdform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <v-select
                        label="ระดับชั้น"
                        :items="rate_work_level_classSelect"
                        item-text="text"
                        item-value="value"
                        v-model="addrate_work_course_std.rate_work_level_class"
                        require
                        :rules="[v => !!v || '']"
                      ></v-select>
                    </v-flex>

                    <v-flex md6>
                      <v-select
                        label="หลักสูตร"
                        :items="year_courseSelect"
                        item-text="text"
                        item-value="value"
                        v-model="addrate_work_course_std.year_course"
                        require
                        :rules="[v => !!v || '']"
                      ></v-select>
                    </v-flex>

                    <v-flex md6>
                      <v-select
                        label="ประเภทวิชา"
                        :items="subject_types"
                        item-text="subject_typeName"
                        item-value="subject_typeName"
                        v-model="addrate_work_course_std.type_course"
                        require
                        :rules="[v => !!v || '']"
                      ></v-select>
                    </v-flex>

                    <v-flex md6>
                      {{ addrate_work_course_std.type_course }}
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="รหัสสาขาวิชา"
                        v-model="addrate_work_course_std.id_course_branch"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="ชื่อสาขาวิชา"
                        v-model="addrate_work_course_std.branch_name_th"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md12>
                      <v-text-field
                        label="ชื่อสาขาวิชา ENG"
                        v-model="addrate_work_course_std.branch_name_eng"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6>
                      <v-text-field
                        label="รหัสสาขางาน"
                        v-model="addrate_work_course_std.id_course_branch_sub"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="ชื่อสาขางาน"
                        v-model="addrate_work_course_std.branch_sub_name_th"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md12>
                      <v-text-field
                        label="ชื่อสาขางาน ENG"
                        v-model="addrate_work_course_std.branch_sub_name_eng"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="addrate_work_course_stddialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="addrate_work_course_stdSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deleterate_work_course_stddialog -->
      <v-layout>
        <v-dialog
          v-model="deleterate_work_course_stddialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลสาขาวิชาเอก"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deleterate_work_course_stdform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูลสาขาวิชาเอก
                          {{ editrate_work_course_std.branch_sub_name_th }}
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="deleterate_work_course_stddialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deleterate_work_course_stdSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editrate_work_course_stddialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editrate_work_course_stddialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลสาขาวิชา"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editrate_work_course_stdform" lazy-validation>
                <v-container grid-list-md>         


                  <v-layout wrap>
                    <v-flex md6>
                      <v-select
                        label="ระดับชั้น"
                        :items="rate_work_level_classSelect"
                        item-text="text"
                        item-value="value"
                        v-model="editrate_work_course_std.rate_work_level_class"
                        require
                        :rules="[v => !!v || '']"
                      ></v-select>
                    </v-flex>

                    <v-flex md6>
                      <v-select
                        label="หลักสูตร"
                        :items="year_courseSelect"
                        item-text="text"
                        item-value="value"
                        v-model="editrate_work_course_std.year_course"
                        require
                        :rules="[v => !!v || '']"
                      ></v-select>
                    </v-flex>

                    <v-flex md6>
                      <v-select
                        label="ประเภทวิชา"
                        :items="subject_types"
                        item-text="subject_typeName"
                        item-value="subject_typeName"
                        v-model="editrate_work_course_std.type_course"
                        require
                        :rules="[v => !!v || '']"
                      ></v-select>
                    </v-flex>

                    <v-flex md6>
                      {{ editrate_work_course_std.type_course }}
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="รหัสสาขาวิชา"
                        v-model="editrate_work_course_std.id_course_branch"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="ชื่อสาขาวิชา"
                        v-model="editrate_work_course_std.branch_name_th"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md12>
                      <v-text-field
                        label="ชื่อสาขาวิชา ENG"
                        v-model="editrate_work_course_std.branch_name_eng"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6>
                      <v-text-field
                        label="รหัสสาขางาน"
                        v-model="editrate_work_course_std.id_course_branch_sub"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="ชื่อสาขางาน"
                        v-model="editrate_work_course_std.branch_sub_name_th"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md12>
                      <v-text-field
                        label="ชื่อสาขางาน ENG"
                        v-model="editrate_work_course_std.branch_sub_name_eng"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editrate_work_course_stddialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editrate_work_course_stdSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model man_powerCSVdialog -->
      <v-layout>
        <v-dialog v-model="man_powerCSVdialog" persistent max-width="50%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="นำเข้าข้อมูลสาขาวิชา"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-card-text>
                  <v-form ref="man_powerCSVdialogform" lazy-validation>
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-btn
                            block
                            large
                            color="info"
                            to="/admin/rate_work_course_std_import/"
                            target="_blank"
                          >
                            <v-icon>mdi-exit-to-app</v-icon
                            >ส่งออกแบบฟอร์มบันทึกข้อมูล</v-btn
                          >
                        </v-col>
                        <v-col cols="12" md="12">
                          <h2>
                            <v-file-input
                              v-model="file2"
                              accept=".csv"
                              name="csvFiles"
                              color="deep-purple accent-4"
                              counter
                              label="ไฟล์ .csv"
                              placeholder="ไฟล์ CSV สาขาวิชา"
                              prepend-icon="mdi-paperclip"
                              outlined
                              rounded
                              :show-size="1000"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 2"
                                  color="deep-purple accent-4"
                                  dark
                                  label
                                  small
                                >
                                  {{ text }}
                                </v-chip>

                                <span
                                  v-else-if="index === 2"
                                  class="text-overline grey--text text--darken-3 mx-2"
                                >
                                  +{{ files.length - 2 }} File(s)
                                </span>
                              </template>
                            </v-file-input>
                          </h2>

                          <v-btn
                            class="pa-2"
                            block
                            large
                            color="green"
                            @click.stop="csvManpowerSubmit()"
                          >
                            <v-icon>mdi-book-plus</v-icon>
                            นำเข้า</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="man_powerCSVdialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      addrate_work_course_stddialog: false,
      editrate_work_course_stddialog: false,
      deleterate_work_course_stddialog: false,
      man_powerCSVdialog: false,
      file2: null,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      rate_work_course_stds: [],
      addrate_work_course_std: {},
      editrate_work_course_std: {},
      search: "",
      type_courseSelect: "",
      pagination: {},
      headers: [
        { text: "หลักสูตรปีการศึกษา", align: "center", value: "year_course" },
        { text: "รหัสประเภทวิชา", align: "center", value: "id_type_course" },
        { text: "ประเภทวิชา", align: "left", value: "type_course" },
        { text: "รหัสสาขาวิชา", align: "left", value: "id_course_branch" },
        { text: "ชื่อสาขาวิชา", align: "left", value: "branch_name_th" },
        { text: "ชื่อสาขาวิชา-ENG", align: "left", value: "branch_name_eng" },
        { text: "รหัสสาขางาน", align: "left", value: "id_course_branch_sub" },
        { text: "ชื่อสาขางาน", align: "left", value: "branch_sub_name_th" },
        {
          text: "ชื่อสาขางาน-ENG",
          align: "left",
          value: "branch_sub_name_eng"
        },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      college: {},
      provinces: [],
      prefectures: [],
      rate_work_course_stdstatus: [],
      subject_types: [],
      addid_type_course: {},
      regions: [],
      region_ena: true,
      rate_work_level_classSelect: [
        { text: "ปวช.", value: "20" },
        { text: "ปวส.", value: "30" }
      ],
      year_courseSelect: [
        { text: "ปวช.62", value: "ปวช.62" },
        { text: "ปวช.65", value: "ปวช.65" },
        { text: "ปวช.66", value: "ปวช.66" },
        { text: "ปวช.67", value: "ปวช.67" },
        { text: "ปวช.68", value: "ปวช.68" },
        { text: "ปวช.65", value: "ปวช.65" },
        { text: "ปวช.65", value: "ปวช.65" },
        { text: "ปวส.63", value: "ปวส.63" },
        { text: "ปวส.64", value: "ปวส.64" },
        { text: "ปวส.65", value: "ปวส.65" },
        { text: "ปวส.66", value: "ปวส.66" },
        { text: "ปวส.67", value: "ปวส.67" },
        { text: "ปวส.68", value: "ปวส.68" }
      ]
    };
  },
  async mounted() {
    let result;
    result = await this.$http.post("collegetype.php", {
      ApiKey: this.ApiKey
    });
    this.collegetypes = result.data;
    result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });

    this.colleges = result.data;
    result = await this.$http.post("province.php", {
      ApiKey: this.ApiKey
    });
    this.provinces = result.data;

    result = await this.$http.post("region.php", {
      ApiKey: this.ApiKey
    });
    this.regions = result.data;

    this.rate_work_course_stdQueryAll();
    this.subject_typeGroupBranch();
  },
  methods: {
    async csvManpowerSubmit() {
      let result = "";
      let uploaded = null;
      if (this.file2) {
        let formData = new FormData();
        let filename = this.time_stamp + ".manpower.csv";
        formData.append("file", this.file2);
        formData.append("filename", "../HRvecfiles/" + filename);
        formData.append("ApiKey", this.ApiKey);
        result = await this.$http.post("man_powercsv.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });

        uploaded = true;
      } else {
        uploaded = false;
      }
      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.man_powerQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
        this.man_powerQueryAll();
      }
      this.man_powerCSVdialog = false;
    },
    async man_powerCSV() {
      this.man_powerCSVdialog = true;
    },

    async rate_work_course_stdUpdate() {
      if (this.$refs.updaterate_work_course_stdform.validate()) {
        this.editrate_work_course_std.ApiKey = this.ApiKey;
        this.editrate_work_course_std.type_course = this.type_courseSelect;
        
        let result = await this.$http.post(
          "rate_work_course_std.updateIdtypeCourse.php",
          this.editrate_work_course_std
        );
        if (result.data.status == true) {
          this.rate_work_course_std = result.data;
           Swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.rate_work_course_stdQueryAll();
        } else {
           Swal.fire({
            icon: "warning",
            title: "แก้ไขข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.rate_work_course_stdQueryAll();
      }
    },
    async rate_work_course_stdQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("rate_work_course_std.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.rate_work_course_stds = result.data;
    },

    async rate_work_course_stdSearch() {
      this.loading = true;
      let result = await this.$http
        .post("rate_work_course_std.php", {
          ApiKey: this.ApiKey,
          type_course: this.type_courseSelect
        })
        .finally(() => (this.loading = false));
      this.rate_work_course_stds = result.data;
      ;
    },

    async subject_typeGroupBranch() {
      this.loading = true;
      let result = await this.$http
        .post("subject_type.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.subject_types = result.data;
    },

    async rate_work_course_stdAdd() {
      this.addrate_work_course_std = {};
      this.addrate_work_course_stddialog = true;
    },
    async addrate_work_course_stdSubmit() {
      if (this.$refs.addrate_work_course_stdform.validate()) {
        this.addrate_work_course_std.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "rate_work_course_std.insert.php",
          this.addrate_work_course_std
        );
        if (result.data.status == true) {
          this.rate_work_course_std = result.data;
          this.snackbar.icon = "mdi-font-awesome";
          this.snackbar.color = "success";
          this.snackbar.text = "บันทึกข้อมูลเรียบร้อย";
          this.snackbar.show = true;
          this.rate_work_course_stdQueryAll();
        } else {
           Swal.fire({
            icon: "warning",
            title: "บันทึกข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
          this.rate_work_course_stdQueryAll();
        }
        this.addrate_work_course_stddialog = false;
      }
    },
    async rate_work_course_stdEdit(rate_id_course) {
      let result = await this.$http.post("rate_work_course_std.php", {
        ApiKey: this.ApiKey,
        rate_id_course: rate_id_course
      });
      this.editrate_work_course_std = result.data;
      this.editrate_work_course_stddialog = true;
    },
    async editrate_work_course_stdSubmit() {
      if (this.$refs.editrate_work_course_stdform.validate()) {
        this.editrate_work_course_std.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "rate_work_course_std.update.php",
          this.editrate_work_course_std
        );
        if (result.data.status == true) {
          this.rate_work_course_std = result.data;
           Swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.rate_work_course_stdQueryAll();
        } else {
           Swal.fire({
            icon: "warning",
            title: "แก้ไขข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editrate_work_course_stddialog = false;
      }
    },
    async rate_work_course_stdDelete(rate_id_course) {
      let result = await this.$http.post("rate_work_course_std.php", {
        ApiKey: this.ApiKey,
        rate_id_course: rate_id_course
      });
      this.editrate_work_course_std = result.data;
      this.deleterate_work_course_stddialog = true;
    },
    async deleterate_work_course_stdSubmit() {
      if (this.$refs.deleterate_work_course_stdform.validate()) {
        this.editrate_work_course_std.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "rate_work_course_std.delete.php",
          this.editrate_work_course_std
        );
        ;
        if (result.data.status == true) {
          this.rate_work_course_std = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.rate_work_course_stdQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deleterate_work_course_stddialog = false;
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "blue darken-4";
    }
  }
};
</script>
